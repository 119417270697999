<script>
import Vue              from 'vue'
import { mapGetters }   from 'vuex'
import Utils            from '../../lib/utils'
import savedOrdersList  from '../../apps/saved_orders_list'
import NiceI18n         from '../../lib/nice_i18n'

export default Vue.component('saved-order', {
  props: {
    item: {
      default: () => {
        return {}
      },
      type: Object
    },
    listPageUrl: {
      type: String,
      default: undefined
    },
    noDefaultTitle: {
      type: Boolean,
      default: false
    },
    openNewLineItemOnStartup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errors: {},
      useDefaultTitle: true
    }
  },
  computed: {
    ...mapGetters([
      'apiPath'
    ])
  },
  beforeMount() {
    if (this.item.id) {
      this.calculateDisplayOrder()
    }

    if (this.noDefaultTitle && this.hasDefaultTitle()) {
      this.useDefaultTitle = false
      this.item.title = null
    }
  },
  mounted() {
    if (!this.item.id && this.openNewLineItemOnStartup && this.openNewLineItemOnStartup && this.item.line_items) {
      this.addLineItemEl()
    }
  },
  methods: {
    /*
     * Add the saved order to cart
     */
    create() {
      this.$http.post(`${this.apiPath}/saved_orders`, {
        order_type_id: 4,
        saved_order: { title: this.item.title },
        products: this.formatLineItemsForPost()
      }).then(response => {
        if (response.body.errors) {
          this.errors = response.body.errors
          this.$store.dispatch('triggerEvent', { type: 'gy::saved-order-not-created', message: this.errors })
        } else {
          this.errors = {}
          this.item.id = response.body.saved_order.id // fetch the id
          this.$store.dispatch('triggerEvent', { type: 'gy::saved-order-created', message: response.body.message })

          if (this.item.isQuickOrder) this.$emit('gy:saved-order-quick-order-created')
        }
      })
    },

    /*
     * Updates the saved order
     */
    update() {
      this.$http.post(`${this.apiPath}/saved_orders/${this.item.id}/add_to_cart`, {
        update_saved_order: "true",
        order_type_id: 4,
        addition: "false",
        products: this.formatLineItemsForPost(),
        title: this.item.title
      }).then(response => {
        if (response.body.errors) {
          this.errors = response.body.errors
          this.$store.dispatch('triggerEvent', { type: 'gy::saved-order-quick-not-updated', message: this.errors })
        } else {
          // this.item = response.body.saved_order // this raises a warning, but at the moment valid/possible
          this.errors = {}
          this.$store.dispatch('triggerEvent', { type: 'gy::saved-order-updated', message: response.body.message })
        }
      })
    },

    /*
     * Saves the order depending on whether or not it's been already saved
     */
    save() {
      if (this.emptyOrder()) {
        this.$store.dispatch('triggerEvent', {
          type: 'gy::saved-order-no-line-items-on-save',
          message: NiceI18n.t('saved_orders.saved_order.no_line_items_on_save')
        })
      } else {
        this.item.id ? this.update() : this.create()
      }
    },

    /*
     * Saves an quick order
     */
    saveQuickOrder() {
      this.save()
    },

    /*
     * Add the saved order to cart
     */
    addToCart() {
      if (document.querySelector('#gy-saved-orders') || confirm(NiceI18n.t('saved_orders.saved_order.add_to_cart_prompt'))) {
        this.$http.post(`${this.apiPath}/saved_orders/${this.item.id}/add_to_cart`, {
          add_saved_order_to_cart: "true"
        }).then(response => {
          this.$store.dispatch('loadOrder')

          this.$store.dispatch('triggerEvent', {
            type: 'gy::saved-order-added-to-cart',
            message: response.body.message
          })
        })
      }
    },

    /*
     * Adds quick order to cart
     */
    addQuickOrderToCart() {
      this.$http.post(`${this.apiPath}/order`, {
        products: this.formatLineItemsForPost()
      }).then(response => {
        this.$store.dispatch('loadOrder')
        this.$store.dispatch('triggerEvent', { type: 'gy::saved-order-quick-added-to-cart', message: response.body.message })
      })
    },

    /*
     * Deletes the saved order
     */
    destroy() {
      if (confirm(NiceI18n.t('saved_orders.saved_order.prompt_to_delete'))) {
        this.$http.delete(`${this.apiPath}/saved_orders/${this.item.id}`, {
        }).then(response => {
          if (savedOrdersList.$el.id !== '')
            savedOrdersList.loadSavedOrders()

          this.$store.dispatch('triggerEvent', { type: 'gy::saved-order-deleted', message: response.body.message })
          this.redirectToListPage()
        })
      }
    },

    /*
     * Adds a new row for line item insertion (not-persisted)
     */
    addLineItemEl() {
      if (this.item.line_items.length == 0 || this.item.line_items.filter(line_item => line_item.product_id == null).length == 0) {
        this.item.line_items.unshift({
          id: Date.now(),
          order_id: this.item.id,
          product_id: null,
          sku: {},
          options: {},
          quantity: 1,
          price: null,
          discounted_price: null,
          total_discounted_price: null,
          vat_rate: null,
          custom_attributes: {}
        })
      }
    },

    /*
    * Updates the line item element and re-calculates saved order costs
    * @param {object} a Line Item object
    */
    updateLineItemEl(lineItem) {
      let index = this.item.line_items.findIndex(line_item => line_item.id == lineItem.id)
      let order_id = this.item.line_items[index].order_id
      lineItem.order_id = order_id
      Vue.set(this.item.line_items, index, lineItem)
      this.calculateDisplayOrder()
    },

    /*
    * Removes the row for the new (i.e. not persisted) line item
    * @param {integer} line item id
    */
    deleteLineItemEl(lineItemId) {
      let index = this.item.line_items.findIndex(line_item => line_item.id == lineItemId)
      this.item.line_items.splice(index, 1)
      Vue.set(this.item, "line_items", this.item.line_items)
      this.calculateDisplayOrder()
    },

    /*
    * Deletes all the checked line items
    * @param {integer} line item id
    */
    deleteCheckedLineItems() {
      let checked = this.item.line_items.filter(line_item => line_item.is_checked == true)

      for (var i = 0, len = checked.length; i < len; i++) {
        let index = this.item.line_items.findIndex(line_item => line_item.id == checked[i].id)
        this.item.line_items.splice(index, 1)
      }

      Vue.set(this.item, "line_items", this.item.line_items)
      this.calculateDisplayOrder()
      this.item.check_all = false
    },

    /*
     * Construct the "products" param that will be posted in update()
     */
    formatLineItemsForPost() {
      var products = Array()

      for (var i = 0, len = this.item.line_items.length; i < len; i++) {
        products.push([ this.item.line_items[i].product_id, {
          product_id: this.item.line_items[i].product_id,
          options: this.item.line_items[i].options,
          quantity: this.item.line_items[i].quantity,
          line_item_attributes: this.item.line_items[i].custom_attributes
        }])
      }

      return products
    },

    /*
     * Redirect to saved orders list page
     */
    redirectToListPage() {
      if (this.listPageUrl) {
        setTimeout(() => {
          window.location = this.listPageUrl
        }, 1500);
      }
    },

    hasDefaultTitle() {
      return this.item.title == NiceI18n.t('saved_orders.saved_order.default_title')
    },

    /*
    * Toggles inline edit functionality for the saved order's title
    */
    toggleTitleEdit() {
      Vue.set(this.item, 'title_edit', !this.item.title_edit)

      let input = this.$refs.order_title

      // Focus input field
      if (this.item.title_edit) {
        if (this.hasDefaultTitle()) {
          Vue.set(this.item, 'title', null)
        }

        Vue.nextTick(function() {
          input.focus()
        })
      }
    },

    /*
    * On blur event (currently only toggles inline edit back to normal)
    */
    saveTitleEdit() {
      if ((!this.item.title || this.item.title == '') && this.useDefaultTitle) {
        Vue.set(this.item, 'title', NiceI18n.t('saved_orders.saved_order.default_title'))
      }

      this.toggleTitleEdit()
    },

    /*
    * Calculates saved order
    * total price, total discounted price and total quantity
    */
    calculateDisplayOrder() {
      var total_price = 0.0,
        total_discounted_price = 0.0,
        total_quantity = 0,
        discounted_price = 0.0

      for (var i = 0, len = this.item.line_items.length; i < len; i++) {
        // check for new element that does not have a discounted price
        discounted_price = this.item.line_items[i].discounted_price
        if (discounted_price == null) continue

        total_price += Utils.formatPriceForCalc(discounted_price, window.current_currency.separator, window.current_currency.decimal_mark, window.current_currency.symbol)
        total_discounted_price += Utils.formatPriceForCalc(this.item.line_items[i].total_discounted_price, window.current_currency.separator, window.current_currency.decimal_mark, window.current_currency.symbol)
        total_quantity += parseInt(this.item.line_items[i].quantity)
      }

      this._displayTotalPrice = Utils.formatCurrency(total_price, window.current_currency.decimal_mark, window.current_currency.symbol)
      this._displayTotalDiscountedPrice = Utils.formatCurrency(total_discounted_price, window.current_currency.decimal_mark, window.current_currency.symbol)
      this._displayTotalQuantity = total_quantity

      return null
    },

    /*
    * Displays the total price of the temporary saved order.
    * This is necessary due to the required re-calculation that is done on the
    * front-end side without API calls, since order is not saved until user clicks Save.
    */
    displayTotalPrice() {
      return this._displayTotalPrice == undefined ? this.item.total_cost : this._displayTotalPrice
    },

    /*
    * Displays the total discounted price of the temporary saved order.
    * This is necessary due to the required re-calculation that is done on the
    * front-end side without API calls, since order is not saved until user clicks Save.
    */
    displayTotalDiscountedPrice() {
      return this._displayTotalDiscountedPrice == undefined ? this.item.discounted_cost : this._displayTotalDiscountedPrice
    },

    /*
    * Displays the total quantity of the temporary saved order.
    * This is necessary due to the required re-calculation that is done on the
    * front-end side without API calls, since order is not saved until user clicks Save.
    */
    displayTotalQuantity() {
      return this._displayTotalQuantity == undefined ? this.item.total_quantity : this._displayTotalQuantity
    },

    /*
    * Returns true if there are any checked line items
    */
    hasCheckedLineItems() {
      if (this.item.line_items) {
        return this.item.line_items.filter(line_item => line_item.is_checked == true).length > 0
      } else {
        return false
      }
    },

    /*
    * Returns true if all line items are checked
    */
    allLineItemsChecked() {
      if (this.item.line_items) {
        return this.item.line_items.filter(line_item => line_item.is_checked == true).length == this.item.line_items.length
      } else {
        return false
      }
    },

    /*
    * Toggles line items' checkbox
    */
    toggleLineItemsCheck() {
      let allLineItemsChecked = !this.allLineItemsChecked()
      this.item.check_all = allLineItemsChecked

      for (var i = 0, len = this.item.line_items.length; i < len; i++) {
        this.item.line_items[i].is_checked = allLineItemsChecked
      }
    },

    /*
    * Handles a line item's checked status change
    * in respect with the check_all checkbox of the order
    */
    handleLineItemCheck() {
      this.allLineItemsChecked() ? this.item.check_all = true : this.item.check_all = false
    },

    /*
    * Returns true when the saved order does not have any line items
    */
    emptyOrder() {
      return typeof(this.item.line_items) == "undefined" || this.item.line_items.filter(line_item => line_item.product_id).length < 1
    },

    /*
    * Returns the title error, if present
    */
    errorTitle() {
      return this.errors && this.errors.title ? this.errors.title[0] : undefined
    }
  },
  template: '#saved-order-template'
})
</script>

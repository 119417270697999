<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import versionsMixin from '../../mixins/versions_mixin'

export default {
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  mixins: [versionsMixin],
  data() {
    return {
      order: {}
    }
  },
  computed: {
    ...mapGetters([
      'apiPath'
    ])
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      return axios.get(
        `${this.apiPath}/order/${this.id}`, { params: { versions: this.stringifiedVersions } }
      ).then((response) => {
        this.order = response.data.order
      })
    }
  }
}
</script>
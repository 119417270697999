import axios from 'axios'

axios.defaults.headers.common['x-csrf-token'] = !!document.querySelector('meta[name="csrf-token"]') && document.querySelector('meta[name="csrf-token"]').content
axios.defaults.headers.common['Authorization'] = window.api_token
axios.defaults.headers.common['x-requested-with'] = 'XMLHttpRequest'

export function getSearchItems(apiPath, itemsLimit, searchText, cancelSource) {
  return axios.get(
    `${apiPath}`, {
      params: {
        per: itemsLimit,
        query: searchText
      },
      cancelToken: cancelSource.token
    }
  )
}

export function getWishlistItems(apiPath, stringifiedVersions) {
  return axios.get(`${apiPath}/wishlist_items`, {
    params: {
      versions: stringifiedVersions
    }
  })
}

export function getCustomPrice(apiPath, productId, selected, quantity) {
  return axios.get(`${apiPath}/products/${productId}/price`, {
    params: {
      selected,
      quantity
    }
  })
}

export function getOrder(apiPath, stringifiedVersions, customAttributes, stringifiedOrderOptions) {
  return axios.get(`${apiPath}/order`, {
    params: {
      versions: stringifiedVersions,
      customAttributes,
      options: stringifiedOrderOptions
    }
  })
}

export function validateZipCode(apiPath, code, countryId) {
  return axios.post(`${apiPath}/zip_codes/validate`, {
    zip_code: code,
    country_id: countryId
  })
}

<template>
  <div class="gy-gdpr-consents">
    <span
      v-for="field in screen.fields"
      :key="field.title"
      class="gy-gdpr-checkbox-wrapper"
    >
      <PCheck
        v-model="field.agree"
        class="p-icon p-curve p-pulse"
        name="check"
        color="success"
      >
        <i
          slot="extra"
          class="icon mdi mdi-check"
        />
        <span
          :class="['consent-label', { 'required-consent': field.required }]"
          v-html="field.title"
        />
      </PCheck>
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
import PrettyCheck from 'pretty-checkbox-vue/check'

Vue.component('PCheck', PrettyCheck)

export default Vue.component('consents', {
  props: ['type']
})
</script>


<style lang="scss">
  @import '~pretty-checkbox/src/pretty-checkbox.scss';
  @import url('https://s3.gy.digital/icons/css/materialdesignicons.min.css?v=3.5.95');

  span.required-consent p:first-child {
    &::before {
      content: " * ";
    }
  }

  span.consent-label a {
    z-index: 5;
    position: relative;
  }

  .pretty .state label {
    font-size: inherit;
    padding-left: 4px;
  }

  .gy-gdpr-checkbox-wrapper {
    font-size: 13px;
  }

  .gy-gdpr-consent-success,
  .gy-gdpr-consent-error {
    margin: 0.6em 0;
  }

  .gy-gdpr-consent-success {
    color: green;
  }

  .gy-gdpr-consent-error {
    color: red;
  }

  .gy-gdpr {
    display: inline-block;
  }

  .gy-gdpr-testing-enabled {
    position: relative;

    .gdpr-testing-label {
      display: inline-block;
      position: absolute;
      top: -23px;
      left: 10px;
      padding: 4px 6px;
      height: 23px;
      font-weight: bold;
      background: #f0f;
      color: white;
      font-size: 11px;
      border: 1px solid white;
      border-bottom: 0;
      z-index: 9999999;
    }
  }
</style>
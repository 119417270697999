<template>
  <div
    v-if="isGiftCardValueField"
    class="form-group"
    :class="className"
  >
    <div class="row">
      <div class="col-4">
        <div class="dropdown d-inline-block">
          <button
            class="btn"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ giftCardValue }} €
          </button>
          <div class="dropdown-menu">
            <div
              v-for="value in values"
              :key="value"
              class="dropdown-item"
            >
              <a
                href="#"
                @click.prevent="select(value)"
              >
                {{ value }}
                <span v-if="!isLast(value)">
                  {{ currentCurrency.symbol }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-8">
        <div
          v-if="displayCustomValue"
          class="input-group"
        >
          <input
            :value="value"
            type="number"
            :placeholder="option.title"
            :class="option.handle"
            class="form-control"
            :min="min"
            :max="max"
            @input="$emit('input', $event.target.value)"
          >
          <label>{{ option.title }}</label>
          <div class="input-group-append">
            <span class="input-group-text">
              {{ currentCurrency.symbol }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-else-if="isTextareaField"
    class="form-group"
    :class="className"
  >
    <textarea
      :value="value"
      :placeholder="option.title"
      :required="option.validate_presence"
      :class="option.handle"
      class="form-control"
      rows="6"
      @input="$emit('input', $event.target.value)"
    />
    <label>{{ option.title }}</label>
  </div>

  <div
    v-else-if="isOtherField"
    class="form-group"
    :class="className"
  >
    <input
      :value="value"
      :type="option.input_type"
      :placeholder="option.title"
      :required="option.validate_presence"
      class="form-control"
      @input="$emit('input', $event.target.value);delayTouch($v.value)"
    >
    <label>{{ option.title }}</label>

    <div class="error" v-if="this.validatesPresence">
      <span v-if="$v.value.$dirty && !$v.value.required">{{ requiredFieldError }}</span>
      <span v-if="$v.value.$dirty && !$v.value.email">{{ emailFieldError }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { store } from '../../lib/store'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import NiceI18n from '../../lib/nice_i18n'
import _ from 'lodash'

const touchMap = new WeakMap()

export default {
  mixins: [validationMixin],
  props: {
    value: {
      required: true,
      type: [String, Number],
      default: ""
    },
    option: {
      required: true,
      type: Object
    },
    className: {
      required: false,
      type: String,
      default: ""
    },
    min: {
      required: false,
      type: Number,
      default: 10
    },
    max: {
      required: false,
      type: Number,
      default: 1000
    },
    values: {
      required: false,
      type: Array,
      default: () => [20, 30, 50, 70, "Άλλο"]
    }
  },
  data() {
    return {
      customValue: null,
      displayCustomValue: false,
      giftCardValue: this.values[0]
    }
  },
  validations() {
    if (!this.validatesPresence)
      return {}

    if (this.isEmailField) {
      return {
        value: {
          required,
          email
        }
      }
    } else {
      return {
        value: {
          required
        }
      }
    }
  },
  computed: {
    ...mapState([
      'currentCurrency'
    ]),
    isGiftCardValueField() {
      return (this.option || {}).handle == "gift_card_value"
    },
    isTextareaField() {
      return (this.option || {}).input_type == "textarea"
    },
    isEmailField(){
      return (this.option || {}).input_type == "email"
    },
    isOtherField() {
      return !(this.isGiftCardValueField && this.isTextareaField)
    },
    validatesPresence() {
      return (this.option || {}).validate_presence || false
    },
    requiredFieldError() {
      return NiceI18n.t("activerecord.errors.models.line_item.attributes.option.required")
    },
    emailFieldError() {
      return NiceI18n.t("activerecord.errors.models.line_item.attributes.option.email_error")
    }
  },
  watch: {
    value: _.debounce(function(after, before) {
      if (!this.displayCustomValue)
        return

      let value

      if (after < this.min) {
        value = this.min
      }
      else if (after > this.max) {
        value = this.max
      }
      else {
        value = after
      }

      this.setGiftCardValue(value)
    }, 400)
  },
  mounted() {
    // Preload default value of giftcard
    if (this.isGiftCardValueField) {
      this.$emit('input', this.giftCardValue)
      this.setGiftCardValue(this.giftCardValue)
    }
  },
  methods: {
    /*
     * Selects gift card value from dropdown. Used only if the custom option is of type gift card value.
     */
    select(value) {
      this.displayCustomValue = this.isLast(value)
      this.giftCardValue = value

      if (!this.displayCustomValue) {
        this.$emit('input', value)
        this.setGiftCardValue(value)
      }
    },

    /*
     * Detects if given value is last element of the values prop.
     * Uses lodash last method to select last element of array.
     */
    isLast(value) {
      return (value == _.last(this.values))
    },

    /*
     * Commits gift card value in store
     */
    setGiftCardValue(value) {
      this.$store.commit('setGiftCardValue', value)
    },

    /*
     * Delays validations errors by 500ms. This allows the user to first type
     * and then only when he stops typing to display the error.
     * Implementation is based on Vuelidate docs.
     */
    delayTouch($v) {
      if (!$v)
        return

      $v.$reset()

      if (touchMap.has($v))
        clearTimeout(touchMap.get($v))

      touchMap.set($v, setTimeout($v.$touch, 500))
    }
  }
}
</script>

<style lang="scss" scoped>
  .error {
    min-height: 2.8rem;
    padding: 5px 0;
  }
</style>
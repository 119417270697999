import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'

import wishlistMixin from '../mixins/wishlist_mixin'
import Product from '../components/product.vue'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

const el = document.getElementById("gy-mini-wishlist")

if (el) {
  new Vue({
    el: el,
    store,
    components: {
      Product
    },
    mixins: [wishlistMixin]
  })
}

import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import VueCookie from 'vue-cookie'
import SiteSelector from '../components/site_selector.vue'
import Search from '../components/search/Search.vue'
import Order from '../components/orders/Order.vue'
import Promotions from '../components/promotions/promotions.vue'
import Stores from '../components/stores.vue'
import GiftList from '../components/gift_lists/GiftList.vue'
import GiftLists from '../components/gift_lists/GiftLists.vue'
import GiftListForm from '../components/gift_lists/GiftListForm.vue'
import GiftListShare from '../components/gift_lists/GiftListShare.vue'

axios.defaults.headers.common['x-csrf-token'] = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['Authorization'] = window.api_token;
axios.defaults.headers.common['x-requested-with'] = 'XMLHttpRequest';

Vue.prototype.$eventbus = new Vue()
Vue.use(Vuelidate)
Vue.use(VueCookie)

const elements = document.getElementsByClassName("gy-app")

for (let el of elements) {
  new Vue({
    el: el,
    store,
    components: {
      SiteSelector,
      Search,
      Order,
      Promotions,
      Stores,
      GiftList,
      GiftLists,
      GiftListForm,
      GiftListShare
    },
    computed: {
      ...mapState([
        'order',
        'wishlistItems',
        'recentlyAdded'
      ]),
      ...mapGetters([
        'signedIn'
      ]),
      cartTotalQuantity() {
        return this.order.total_quantity
      },
      wishlistQuantity() {
        return this.wishlistItems.length
      }
    }
  })
}

import _ from 'lodash'

export default {
  methods: {
    /*
     * Set entry to an object with spread operator
     * This method can be used to set on multiselects
     */
    setObjectEntry(object, key, value) {
      object = { ...object, [key]: value }
      return object
    },

    /*
     * Set values of an object to value with spread operator
     * This method can be used to reset multiselects
     */
    setObjectValues(object, value) {
      Object.keys(object).forEach((key) => { object[key] = value })
      object = { ...object }
      return object
    },

    /*
     * Check values of an object if they have the same value
     */
    checkObjectForSameValues(object, value) {
      let setValues = new Set(Object.values(object))
      return setValues.size === 1 && setValues.has(value)
    },

    /*
     * Transform object values to an array based on the indexes of an array
     */
    objectValuesToArrayBasedOnArray(object, array) {
      let transformedArray = new Array(array.length).fill(null)

      if (!object) {
        return transformedArray
      }

      for (let [key, value] of Object.entries(object)) {
        if (value && parseInt(value) != NaN && parseInt(value) > 0) {
          let index = array.indexOf(parseInt(key))
          transformedArray[index] = parseInt(value)
        }
      }
      return transformedArray
    },

    /*
     * Force reactivity for an object
     */
    cloneDeep(object) {
      return _.cloneDeep(object)
    }
  }
}
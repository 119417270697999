import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'
import Coupon  from '../components/coupon.vue'
import ConsentsContainer  from '../components/moad/consents_container.vue'
import cartMixin  from '../mixins/cart_mixin'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

const elements = document.getElementsByClassName("gy-cart")

for (let el of elements) {
  new Vue({
    el: el,
    store,
    components: {
      Coupon,
      ConsentsContainer
    },
    mixins: [cartMixin],
    data() {
      return {
        errors: {}
      }
    },
    computed: {
      ...mapState([
        'user',
        'order',
        'coupon'
      ]),
      ...mapGetters([
        'apiPath'
      ]),
      versions() {
        return (this.$el.attributes.versions || {}).value
      },
      stringifiedVersions() {
        if (this.versions) {
          return JSON.stringify(JSON.parse(this.versions))
        }

        return undefined
      }
    },
    methods: {
      checkout(event) {
        if (this.checkoutDisabled) {
          event.preventDefault()
        }
      },

      /*
       * Save cart as a saved order
       */
      saveCart() {
        this.$http.post(`${this.apiPath}/saved_orders/create_from_cart`, {
          title: this.order.title
        }).then(response => {
          if (response.body.errors) {
            this.errors = response.body.errors
            this.$store.dispatch('triggerEvent', { type: 'gy::saved-order-cart-not-saved', message: this.errors })
          } else {
            this.errors = null
            this.order.title = null
            this.$store.dispatch('triggerEvent', { type: 'gy::saved-order-cart-saved', message: response.body.message })
          }
        })
      },

      /*
      * Returns the title error, if present
      */
      errorTitle() {
        return this.errors && this.errors.title ? this.errors.title[0] : undefined
      }
    }
  })
}

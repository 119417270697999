import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'
import $ from 'jquery'

import Bundle from '../components/bundle.vue'
import Product from '../components/product.vue'
import versionsMixin from '../mixins/versions_mixin'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

const elements = document.getElementsByClassName("gy-bundles")

for (let el of elements) {
  new Vue({
    el: el,
    store,
    components: {
      Bundle,
      Product
    },
    mixins: [versionsMixin],
    data() {
      return {
        bundles: []
      }
    },
    computed: {
      ...mapState([
        'user',
        'order'
      ]),
      ...mapGetters([
        'apiPath'
      ]),
      bundle() {
        if (this.bundles.length > 0) {
          return this.bundles[Math.floor(Math.random() * this.bundles.length)]
        }
        else {
          return {}
        }
      }
    },
    beforeMount() {
      if (this.$el.attributes.productId) {
        this.productId = this.$el.attributes.productId.value

        this.loadBundles()
      }
    },
    methods: {
      /*
       * Loads bundles from the API. Its called once when app is mounted.
       * @event - gy::bundles-loaded event is triggered
       */
      loadBundles() {
        this.$http.get(`${this.apiPath}/products/${this.productId}/promotions`, { params: { versions: this.stringifiedVersions } }).then(response => {
          this.bundles = response.body.promotions

          this.$store.dispatch('triggerEvent', 'gy::bundles-loaded')
        })
      }
    }
  })
}

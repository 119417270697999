import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'
import VuexPersist from 'vuex-persist'

// Lib
import Utils from '../lib/utils'
import { getWishlistItems, getOrder } from '../api/api'

Vue.use(Vuex)

export const vuexLocalStorage = new VuexPersist({
  storage: window.localStorage,
  reducer: state => ({
    activeGiftListId: state.activeGiftListId
  })
})

export const store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    order: {},
    displayGiftListBar: true,
    coupon: {},
    user: { id: undefined },
    wishlistItems: [],
    recentlyAdded: [],
    promotions: [],
    locale: window.locale || 'el',
    site_code: window.site_code || null,
    currentCurrency: window.current_currency || {},
    history: [],
    lineItemImageVersions: {},
    wishlistItemImageVersions: {},
    giftLists: [],
    giftListsLoaded: false,
    activeGiftListId: null,
    addressCreatedFromGiftList: false,
    giftCardValue: 0,
    billing_address: {},
    shipping_address: {},
    sameAsBilling: true,
    versions: {
      "wishlist": undefined,
      "cart": undefined,
      "miniCart": undefined
    },
    customAttributesMiniCart: {},
    orderOptions: {},
    addresses: []
  },
  getters: {
    apiPath: (state) => {
      return `/${state.site_code}/${state.locale}/api/v2`
    },
    catalogPath: (state) => {
      return `/${state.locale}/catalog`
    },
    siteLocalePath: (state) => {
      return `/${state.site_code}/${state.locale}`
    },
    activeGiftList: (state) => {
      return (Utils.detect(state.giftLists, state.activeGiftListId) || {})
    },
    signedIn: state => {
      return state.user && state.user.id > 0
    },
    stringifiedVersions: state => key => {
      let value = state.versions[key]
      if (value)
        return JSON.stringify(JSON.parse(value))
      return undefined
    },
    stringifiedOrderOptions: (state) => {
      let value = state.orderOptions
      if (value) {
        return JSON.stringify(JSON.parse(value))
      }
      return undefined
    }
  },
  mutations: {
    setGiftLists(state, payload) {
      state.giftLists = payload
    },
    setActiveGiftListId(state, payload) {
      state.activeGiftListId = payload
    },
    setAddress(state, payload) {
      // Weird syntax
      // For reference see: https://github.com/tc39/proposal-object-rest-spread & http://stackoverflow.com/questions/19837916/creating-object-with-dynamic-keys
      if (payload.address != undefined) {
        state[payload.type] = payload.address
      }
      else {
        state[payload.type] = { ...state[payload.type], [payload.key]: payload.value }
      }
    },
    setUser(state, payload) {
      state.user = payload
    },
    setCustomAttributesMiniCart(state, payload) {
      state.customAttributesMiniCart = payload
    },
    setOrderOptions(state, payload) {
      state.orderOptions = payload
    },
    setWishlistItems(state, payload) {
      state.wishlistItems = payload
    },
    setMiniCartVersions(state, payload) {
      state.versions.miniCart = payload
    },
    setCartVersions(state, payload) {
      state.versions.cart = payload
    },
    setWishlistVersions(state, payload) {
      state.versions.wishlist = payload
    },
    setOrder(state, payload) {
      state.order = payload
    },
    setCoupon(state, payload) {
      state.coupon = payload
    },
    setAddresses(state, payload) {
      state.addresses = payload
    },
    setSameAsBilling(state, payload) {
      state.sameAsBilling = payload
    },
    setGiftCardValue(state, payload) {
      state.giftCardValue = payload
    }
  },
  actions: {
    triggerEvent(context, object) {
      let message, type

      if (object) {

        if (typeof(object) == "object") {
          type    = object.type
          message = object.message
        }
        else {
          type    = object
          message = ''
        }

        let event = new CustomEvent(type, {
          detail: {
            message: message,
            event: object.event,
            item: object.item,
            error: object.error,
            optionVariantId: object.optionVariantId,
            time: new Date()
          },
          bubbles: true,
          cancelable: true
        })

        Utils.defer("gyEvents", () => {
          setTimeout(function() {
            document.dispatchEvent(event)
          }, 40)
        })
      }
    },
    setHistory(context, options) {
      if (options.component) {
        context.state.history.push(options)
      }
    },

    /*
    * Loads the order and the wishlist from the API. Its called once when mini_cart app is mounted.
    * @event - gy:order-loaded event is triggered
    */
    loadOrder({ commit, state, getters, dispatch }) {
      return getOrder(getters.apiPath, getters.stringifiedVersions("miniCart"), state.customAttributesMiniCart, getters.stringifiedOrderOptions)
        .then(response => {
          commit('setOrder', response.data.order)
          commit('setCoupon', response.data.coupon)
          commit('setUser', response.data.user)

          dispatch('loadWishlistItems')

          dispatch('triggerEvent', 'gy::order-loaded')
        })
    },

    /*
    * Loads the WishlistItems from the API. Its called from action loadOrder
    * @event - gy:wishlist-loaded event is triggered
    */
    loadWishlistItems({ commit, getters, dispatch }) {
      if (getters.signedIn) {
        return getWishlistItems(getters.apiPath, getters.stringifiedVersions("wishlist"))
          .then(response => {
            let wishlist_items = response.data.wishlist_items
            if (wishlist_items != undefined) {
              commit('setWishlistItems', wishlist_items)
              dispatch('triggerEvent', 'gy::wishlist-loaded')
            }
          })
      }
    }
  }
})
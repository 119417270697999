<template>
  <form
    class="search-form"
    :action="catalogPath"
    method="get"
  >
    <input
      type="hidden"
      name="query"
      :value="inputVal"
    >
    <CoolSelect
      ref="coolSelect"
      v-model="selected"
      :search-text="inputVal"
      :items="items"
      :scroll-items-limit="itemsLimit"
      item-value="path"
      item-text="title"
      :reset-search-on-blur="false"
      :menu-items-max-height="menuMaxHeight"
      :placeholder="textSearch"
      :input-el-custom-attributes="{ 'aria-label': textSearch }"
      disable-filtering-by-search
      arrows-disable-instant-selection
      disable-first-item-select-on-enter
      class="flex-item search-form__field"
      @search="onSearch"
      @select="onSelect"
      @blur="onBlur"
      @keyup="onKeyup"
    >
      <template slot="no-data">
        {{ textSlotNoData }}
      </template>
      <template
        slot="item"
        slot-scope="{ item }"
      >
        <SearchItem
          :name="item.title"
          :item="item"
        />
      </template>
      <template slot="after-items-fixed-absolute">
        <div v-show="items.length">
          <div class="after-items">
            <button
              name="button"
              type="submit"
              class="more-search"
            >
              {{ textAllResults }}
            </button>
          </div>
        </div>
      </template>
      <template slot="before-items-fixed">
        <div v-show="items.length">
          <div class="before-items">
            <button
              name="button"
              type="submit"
              class="more-search"
            >
              {{ textAllResults }}
            </button>
          </div>
        </div>
      </template>
    </CoolSelect>
    <slot
      v-show="!loading"
      name="searchButton"
    >
      <button
        name="button"
        type="submit"
        class="search-form__button"
      >
        <i class="icon mdi mdi-magnify" />
        <span class="search-form__button--text">
          {{ textSearch }}
        </span>
      </button>
    </slot>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import NiceI18n from '../../lib/nice_i18n'
import { CoolSelect } from 'vue-cool-select'
import { CancelToken } from 'axios'
import queryString from 'query-string'
import _ from 'lodash'
import SearchItem from './SearchItem.vue'
import { getSearchItems } from "../../api/api"

export default {
  components: {
    CoolSelect,
    SearchItem
  },
  props: {
    autocompleteProductsPath: {
      required: true,
      type: String,
      note: "Configure path for fetching products"
    },
    menuMaxHeight: {
      type: String,
      default: "400px",
      note: "Configure Search Menu Height"
    },
    hideMenuOnBlur: {
      type: Boolean,
      default: true,
      note: "Defines if menu hides after blur. (false for full page search)"
    }
  },
  data() {
    return {
      selected: null,
      items: [],
      itemsLimit: 30,
      lettersLimit: 3,
      loading: false,
      noData: false,
      inputVal: null,
      gotItems: true,
      cancelGetItems: null,
      textSearch: NiceI18n.t('shared.header.search.text_search'),
      textNoData: NiceI18n.t('shared.header.search.no_data'),
      textAllResults: NiceI18n.t('shared.header.search.all_results')
    }
  },
  computed: {
    ...mapGetters([
      'catalogPath'
    ]),

    textNotEnoughLetters() {
      return `${NiceI18n.t('shared.header.search.not_enough_letters.part_1')} ${this.lettersLimit} ${NiceI18n.t('shared.header.search.not_enough_letters.part_2')}`
    },

    textSlotNoData() {
      if (!this.inputVal || this.inputVal.length <= this.lettersLimit)
        return this.textNotEnoughLetters
      else if (this.noData) {
        return this.textNoData
      }
      return ""
    }
  },
  mounted() {
    this.setInputVal()
  },
  methods: {
    /*
     * Called when user inputs in search
     * @param {String} search - Input value from user
     * @return {Promise} - Promise with array of Products for this search
     */
    onSearch(search) {
      this.inputVal = search

      this.noData = false
      if (search.length < this.lettersLimit) {
        this.items = []
        this.loading = false
        return;
      }
      this.loading = true
      return this.getItems(search)
    },

    /*
     * Called when user selects a product
     * @param {Object} selected - Selected product information
     * Redirects to the selected product page
     */
    onSelect(selected) {
      if (selected && selected.path)
        window.location.assign(selected.path)
    },

    /*
     * Called when user blur search
     * @param {Object} selected - Selected product information
     * If hideMenuOnBlur prop is false, makes the menu visible again
     */
    onBlur(selected) {
      if (!this.hideMenuOnBlur)
        this.$refs.coolSelect.showMenu()
    },

    onKeyup(e) {
      let keyCode = e.keyCode || e.which

      if (keyCode == '13') {
        this.$refs.coolSelect.$el.closest('form').submit()
      }
    },

    /*
     * Called from onSearch
     * @param {String} search - Input value from user
     * @return {Promise} - Promise with array of Products of this search
     */
    getItems: _.debounce( function (search) {
      this.checkCancelGetItems(this.cancelGetItems, this.gotItems)
      this.gotItems = false
      this.cancelGetItems = CancelToken.source()

      return getSearchItems(this.autocompleteProductsPath, this.itemsLimit, search, this.cancelGetItems)
        .then(this.onGetItems)
        .catch((e)=> {
          console.log(e)
        })
    }, 130),

    /*
     * Called from getItems. Needed for mocking.
     * @param {Object} cancelGetItems - Cancel token of axios of getItems
     * @param {Boolean} gotItems - Indicates if api call is finished
     * @param {String} txt - Text needed for debug
     * Cancel axios request if gotItems = false
     */
    checkCancelGetItems(cancelGetItems, gotItems, txt="Cancelled from user") {
      if (!gotItems) {
        cancelGetItems.cancel(txt)
      }
    },

    /*
     * Called from getItems. Success callback of getSearchItems api call
     * @param {Object} response - Response from server
     * Gets items, stops loading and checks for no data
     */
    onGetItems(response) {
      if (response.data.products)
        this.items = response.data.products
      else
        this.items = response.data
      this.loading = false
      this.gotItems = true

      if (!this.items.length) this.noData = true;
    },

    /* Called on mount of component. Used to set default
     * search input value when page on page load.
     */
    setInputVal() {
      const parsed = queryString.parse(window.location.search)

      if (parsed["query"] !== undefined)
        this.inputVal = parsed["query"]
    }
  }
};
</script>

<style lang="scss">
.IZ-select__menu {
  position: absolute;
  z-index: 2;
  width: 100%!important;
}

.IZ-select {
  outline: none;
}

.IZ-select__input {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;


  &--selection-slot {
    padding-left: 0.75rem;

    input {
      padding-left: 10px;
    }
  }

  &--has-error {
    border: 1px solid #dc3545;
    caret-color: #ff5252;

    input {
      color: #ff5252;
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: #e9ecef;
    opacity: 1;

    input {
      color: #6c737a;
    }

    &::placeholder {
      color: #6c737a;
    }
  }

  input {
    font-size: 1rem;

    /* Loading indicator */
    background-size: 25px 25px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    padding: 0.375rem 0.75rem;
    border-style: none;
    pointer-events: auto;
    flex: 1 1;
    margin-top: 0;
    min-width: 0;
    line-height: 20px;
    max-width: 100%;
    width: 100%;

    &:focus {
      outline: none;
    }

    &:disabled {
      pointer-events: none;
    }
  }
}

.IZ-select__menu-items {
  -webkit-transform-origin: left top 0;
  transform-origin: left top 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 0 8px;
  box-sizing: border-box;

  .IZ-select__no-data {
    margin: 0 10px;
    text-align: center;
  }
}

.IZ-select__item {
  cursor: pointer;
  padding: 10px 14px;

  &:hover,
  &--selected {
    background-color: #f2f2f2;
  }
}

.IZ-select__error {
  margin-top: 0.55rem;
  font-size: 85%;
  color: #dc3545;
}
</style>

<style lang="scss" scoped>
@import url('https://s3.gy.digital/icons/css/materialdesignicons.min.css?v=3.5.95');

.after-items {
  position: absolute;
  width: 100%;
  button {
    position: relative;
    width: 100%;
    right: unset;
    -webkit-transform: none;
    transform: none;
  }
}

.before-items button {
  display: none;
  width: 100%;
}

.search-form{
  &__button {
    position: absolute;
    top: 0;
    right: 0;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--text {
      opacity: 0;
      display: block;
      height: 0;
      width: 0;
    }
  }
}
</style>

<template>
  <div style="display: inline;">
    <MultiSelect
      v-if="!readonly"
      :value="country"
      :options="countries"
      label="title"
      track-by="id"
      :selected-label="selectedLabel"
      :select-label="selectLabel"
      :deselect-label="deselectLabel"
      :placeholder="placeholderVal"
      :close-on-select="true"
      :clear-on-select="true"
      @select="select"
      @remove="remove"
    />
    <input
      v-if="readonly"
      v-model="country.title"
      readonly="readonly"
      type="text"
    >
    <input
      v-model="country.id"
      type="hidden"
      :name="inputName"
    >
    <span
      v-if="$v.country.id.$error"
      class="error"
    >
      {{ error_message }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import MultiSelect from '../lib/vue-multiselect'
import NiceI18n from '../lib/nice_i18n'
import { validationMixin }   from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'

export default Vue.component('country-select', {
  components: {
    MultiSelect
  },
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: NiceI18n.t("orders.address.text_select_country")
    },
    countryId: {
      type: Number,
      default: undefined
    },
    regionId: {
      type: Number,
      default: undefined
    },
    vatOfficeId: {
      type: Number,
      default: undefined
    },
    selectedLabel: {
      type: String,
      default: ""
    },
    selectLabel: {
      type: String,
      default: ""
    },
    deselectLabel: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      error_message: NiceI18n.t("activerecord.errors.models.address.attributes.country.blank")
    }
  },
  computed: {
    ...mapState([
      'order',
      'sameAsBilling'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    country() {
      if (this.$store.state[this.type].country) {
        return this.$store.state[this.type].country
      } else {
        return {}
      }
    },
    countries() {
      if (this.$store.state[this.type].countries) {
        return this.$store.state[this.type].countries
      }
      else {
        return []
      }
    },
    inputName() {
      return `order[${this.type}_attributes][country_id]`
    },
    placeholderVal() {
      return this.placeholder
    }
  },
  watch: {
    countryId: function(newValue, oldValue) {
      if (oldValue != newValue) {
        this.preselectCountry()
      }
    },
    regionId: function(newValue, oldValue) {
      if (oldValue != newValue) {
        this.preselectRegion()
      }
    },
    vatOfficeId: function(newValue, oldValue) {
      if (oldValue != newValue) {
        this.preselectVatOffice()
      }
    }
  },
  mounted() {
    if (!this.type) {
      Utils.logError("Please define `type` attribute in country element.")
    }
    this.loadCountries()
  },
  validations: {
    country: {
      id: {
        required,
        numeric
      }
    }
  },
  methods: {
    /*
     * It selects the given option.
     * @param {Object} option - the option
     */
    select(option) {
      this.$store.commit('setAddress', { type: this.type, key: 'country', value: option })
      this.$store.commit('setAddress', { type: this.type, key: 'region', value: null })
      this.$store.commit('setAddress', { type: 'billing_address', key: 'vat_office', value: null })

      if (this.type == "billing_address" && this.sameAsBilling) {
        this.$store.commit('setAddress', { type: "shipping_address", key: 'country', value: option })
        this.$store.commit('setAddress', { type: "shipping_address", key: 'region', value: null })
      }

      this.$parent.selectedPaymentMethod  = null
      this.$parent.selectedShippingMethod = null

      this.$v.country.id.$touch()

      this.loadRegions()

      this.loadVatOffices()
    },

    /*
     * Removes the country from the store
     */
    remove() {
      this.$store.commit('setAddress', { type: this.type, key: 'country', value: null })
      this.$v.country.id.$touch()
    },

    /*
     * It loads the countries from API
     * @param {Object} option - the option
     */
    loadCountries() {
      this.$http.get(`${this.apiPath}/countries`).then(response => {
        this.$store.commit('setAddress', { type: this.type, key: 'countries', value: response.body.countries })

        this.preselectCountry()

        this.$store.dispatch('triggerEvent', 'gy::countries-loaded')
      })
    },

    /*
     * It loads the regions from API
     */
    loadRegions() {
      this.$http.get(`${this.apiPath}/regions`, { params: { country_id: this.country.id } }).then(response => {
        this.$store.commit('setAddress', { type: this.type, key: 'regions', value: response.body.regions })

        if (this.type == "billing_address" && this.sameAsBilling) {
          this.$store.commit('setAddress', { type: "shipping_address", key: 'regions', value: response.body.regions })
        }

        this.preselectRegion()
        this.$store.dispatch('triggerEvent', 'gy::regions-loaded')
      })
    },

    /*
     * It loads the vat offices from API
     */
    loadVatOffices() {
      this.$http.get(`${this.apiPath}/vat_offices`, { params: { country_id: this.country.id } }).then(response => {
        this.$store.commit('setAddress', { type: 'billing_address', key: 'vat_offices', value: response.body.vat_offices })

        if (this.vatOfficeId) this.preselectVatOffice()

        this.$store.dispatch('triggerEvent', 'gy::vat-offices-loaded')
      }, response => {
        // Not found
        if (response.status == 404) {}
      })
    },

    /*
     * Preselects the country
     */
    preselectCountry() {
      if (this.countryId) {
        for (let country of this.$store.state[this.type].countries) {
          if (country.id == parseInt(this.countryId)) {
            this.$store.commit('setAddress', { type: this.type, key: 'country', value: country })
            this.loadRegions()
            this.loadVatOffices()
          }
        }
      } else {
        this.select(this.$store.state[this.type].countries[0])
      }
    },

    /*
     * Preselects the region
     */
    preselectRegion() {
      if (this.regionId) {
        for (let region of this.$store.state[this.type].regions) {
          if (region.id == parseInt(this.regionId)) {
            this.$store.commit('setAddress', { type: this.type, key: 'region', value: region })
          }
        }
      }
    },

    /*
     * It loads the preselects the vat office
     * @param {Object} option - the option
     */
    preselectVatOffice() {
      if (this.vatOfficeId && this.$store.state['billing_address'].vat_offices) {
        for (let vatOffice of this.$store.state['billing_address'].vat_offices) {
          if (vatOffice.id == parseInt(this.vatOfficeId)) {
            this.$store.commit('setAddress', { type: 'billing_address', key: 'vat_office', value: vatOffice })
          }
        }
      } else {
        this.$store.commit('setAddress', { type: 'billing_address', key: 'vat_office', value: {} })
      }
    }
  }
})
</script>

import Vue                      from 'vue'
import VueResource              from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store }                from '../lib/store'
import { EventBus }             from '../lib/event_bus.js'
import { debounce }             from 'lodash'

import scroll from 'scroll'
var page = require('scroll-doc')()
var ease = require('ease-component')

import AddressSelect            from '../components/address_select.vue'
import CountrySelect            from '../components/country_select.vue'
import RegionSelect             from '../components/region_select.vue'
import VatOfficeSelect          from '../components/vat_office_select.vue'
import VatNumberField           from '../components/vat_number_field.vue'
import B2bAddressSelect         from '../components/b2b/address_select.vue'
import ZipCodeField             from '../components/orders/ZipCodeField.vue'
import Coupon                   from '../components/coupon.vue'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)

  // return response callback
  return function(response) {
    if (response.status == 401 && typeof(response.headers.map["x-expired-token"]) != "undefined") {
      window.location.reload(true)
    }
  }
})

const el = document.getElementById("gy-checkout")

if (el) {
  new Vue({
    el: el,
    store,
    components: {
      AddressSelect,
      CountrySelect,
      RegionSelect,
      VatNumberField,
      VatOfficeSelect,
      B2bAddressSelect,
      ZipCodeField,
      Coupon
    },
    data() {
      return {
        shippingMethods: [],
        paymentMethods: [],
        selectedPaymentMethod: null,
        selectedShippingMethod: null,
        invoiceSelected: false,
        billing_address: {},
        shipping_address: {},
        corporateCode: undefined
      }
    },
    computed: {
      ...mapState([
        'order'
      ]),
      ...mapGetters([
        'apiPath'
      ]),
      billingCountry() {
        return (this.$store.state.billing_address || {}).country
      },
      billingRegion() {
        return (this.$store.state.billing_address || {}).region
      },
      shippingCountry() {
        return (this.$store.state.shipping_address || {}).country
      },
      shippingRegion() {
        return (this.$store.state.shipping_address || {}).region
      },
      shippingZipCode() {
        return (this.$store.state.shipping_address || {}).zip
      },
      cartEmpty() {
        return this.order.total_quantity == undefined || this.order.total_quantity < 1
      },
      invalidPaymentShippingMethod() {
        if (!this.order.validates_payment_and_shipping_methods) {
          this.scrollToError()
        }
        return null
      },
      sameAsBilling: {
        get () {
          return this.$store.state.sameAsBilling
        },
        set (newValue) {
          return this.$store.commit('setSameAsBilling', newValue)
        }
      }
    },
    watch: {
      billingCountry: function(newValue, oldValue) {
        if (oldValue != newValue) {
          this.refreshPaymentMethods()
        }
      },
      billingRegion: function(newValue, oldValue) {
        if (oldValue != newValue) {
          this.refreshPaymentMethods()
        }
      },
      shippingCountry: function(newValue, oldValue) {
        if (oldValue != newValue) {
          this.refreshShippingMethods()
        }
      },
      shippingRegion: function(newValue, oldValue) {
        if (oldValue != newValue) {
          this.refreshShippingMethods()
        }
      },
      shippingZipCode: function (newValue, oldValue) {
        if (oldValue != newValue)
          this.refreshShippingMethods()
      },
      selectedPaymentMethod: function(newValue, oldValue) {
        if (oldValue != newValue) {
          this.refreshShippingMethods()
        }
      },
      selectedShippingMethod: function(newValue, oldValue) {
        if (oldValue != newValue) {
          this.refreshPaymentMethods()
        }
      },
      invoiceSelected: function(newValue, oldValue) {
        if (oldValue != newValue) {
          if (this.$refs.invoice_radio && newValue == 'true') {
            this.$refs.invoice_radio.click()
          }
          else if (this.$refs.receipt_radio && newValue == 'false') {
            this.$refs.receipt_radio.click()
          }

          this.refreshPaymentMethods()
          this.refreshShippingMethods()
        }
      },
      'order.payment_method_id': function(newValue, OldValue) {
        this.selectedPaymentMethod = newValue
      },
      'order.shipping_method_id': function(newValue, OldValue) {
        this.selectedShippingMethod = newValue
      },
      'order.is_invoice': function(newValue, OldValue) {
        this.invoiceSelected = newValue

        if (this.$refs.invoice_radio && newValue) {
          this.$refs.invoice_radio.click()
        }
        else if (this.$refs.receipt_radio && !newValue) {
          this.$refs.receipt_radio.click()
        }
      },
      invalidPaymentShippingMethod() {
        if (!this.order.validates_payment_and_shipping_methods) {
          this.scrollToError()
        }
      },
      sameAsBilling(val, oldVal) {
        if (val != oldVal && val === true) {
          let addressSelectComponents = this.$children.filter(child => child.$options._componentTag == 'address-select')

          if (addressSelectComponents.length) {
            let billingAddressSelect    = addressSelectComponents.filter(child => child.type == 'billing_address')[0]
            let shippingAddressSelect   = addressSelectComponents.filter(child => child.type == 'shipping_address')[0]

            shippingAddressSelect.select(billingAddressSelect.selectedAddress)
          }
        }
      }
    },
    mounted() {
      this.setCorporateCode()
      this.preCheckSameAsBilling()

      this.billing_address  = this.$store.state.billing_address
      this.shipping_address = this.$store.state.shipping_address

      EventBus.$on('checkout-address-selected', this.populateAddresses)

      if (this.$el.attributes.load) {
        this.$store.dispatch('loadOrder')
      }
    },
    methods: {
      /*
      * Populates the address
      * @event - gy::checkout-address-populated is triggered when activated
      */
      populateAddresses(selectedAddress) {
        this.billing_address  = this.$store.state.billing_address
        this.shipping_address = this.$store.state.shipping_address

        if (this.sameAsBilling) {
          this.shipping_address = this.billing_address
          this.$store.commit("setAddress", { type: "shipping_address", address: this.shipping_address })
        }

        this.$store.dispatch('triggerEvent', 'gy::checkout-address-populated')
      },

      preCheckSameAsBilling() {
        if (this.corporateCode) return

        if (this.$el && this.$el.attributes) {
          let value = (this.$el.attributes.sameAsBilling || {}).value

          if (typeof(value) != 'undefined') {
            this.$store.commit('setSameAsBilling', (value != 'false'))
          }
        }
      },

      setCorporateCode() {
        if (this.$el && this.$el.attributes) {
          let value = (this.$el.attributes.corporateCode || {}).value

          if (typeof(value) != 'undefined') {
            this.corporateCode = value
          }
        }

        if (this.corporateCode) {
          this.$store.commit('setSameAsBilling', false)
        }
      },

      /*
      * It refreshes payment methods.
      * @event - gy::payment-methods-loaded is triggered when activated
      */
      refreshPaymentMethods: debounce(function(e) {
        if (this.billingCountry === undefined)
          return

        this.$http.post(`${this.apiPath}/order/payment_methods`,
          {
            is_invoice: this.invoiceSelected,
            country_id: this.billingCountry.id,
            region_id: (this.billingRegion || {}).id,
            zip_code: this.shippingZipCode,
            shipping_method_id: this.selectedShippingMethod
          }
        )
        .then(response => {
          this.paymentMethods = response.body.payment_methods
          this.$store.dispatch('triggerEvent', 'gy::payment-methods-loaded')

          this.preselectPaymentMethod()
        })

      }, 50),

      /*
      * It refreshes shipping methods.
      * @event - gy::shipping-methods-loaded event is triggered when activated
      */
      refreshShippingMethods: debounce(function(e) {
        if (this.shippingCountry === undefined)
          return

        this.$http.post(`${this.apiPath}/order/shipping_methods`,
          {
            is_invoice: this.invoiceSelected,
            country_id: this.shippingCountry.id,
            region_id: (this.shippingRegion || {}).id,
            zip_code: this.shippingZipCode,
            payment_method_id: this.selectedPaymentMethod
          }
        ).then(response => {
          this.shippingMethods = response.body.shipping_methods
          this.$store.dispatch('triggerEvent', 'gy::shipping-methods-loaded')

          this.preselectShippingMethod()
        })
      }, 50),

      /*
      * Preselect shipping method if we have only one.
      */
      preselectShippingMethod() {
        if (this.shippingMethods.length == 1) {
          this.selectedShippingMethod = this.shippingMethods[0].id
        }
      },

      /*
      * Preselect payment method if we have only one.
      */
      preselectPaymentMethod() {
        if (this.paymentMethods.length == 1) {
          this.selectedPaymentMethod = this.paymentMethods[0].id
        }
      },

      /*
      * Scrolls to present error for ui purposes.
      * Uses offset taken from error element's data in order to adjust offset per project.
      */
      scrollToError() {
        let error = this.$refs.invalid_methods_error

        if (error) {
          let offset = parseInt(error.dataset.offset)
          let y = error.offsetTop - offset

          scroll.top(page, y, { duration: 1000, ease: ease.inOutSine })
        }
      }
    }
  })
}

import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'

import MixNMatch     from '../components/mix_n_match.vue'
import versionsMixin from '../mixins/versions_mixin'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)
  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

const elements = document.getElementsByClassName("gy-mix-n-match")

for (let el of elements) {
  new Vue({
    el: el,

    store,
    computed: {
      ...mapState([
        'order'
      ]),
      ...mapGetters([
        'apiPath'
      ])
    },
    components: {
      MixNMatch
    },
    mixins: [versionsMixin]
  })
}

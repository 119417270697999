<template>
  <a class="row search-result">
    <div class="col-sm-3 text-center">
      <div class="search-result__photo">
        <img
          :src="item.photo"
          alt=""
        >
      </div>
    </div>
    <div class="col-sm-9">
      <a :href="item.path">
        <p class="search-result__title">
          {{ item.title }}
        </p>
        <p class="search-result__code">
          {{ item.code }}
        </p>
      </a>

      <p class="search-result-price">
        <del 
          v-if="item.price != item.discounted_price"
          class="search-result-price__original"
        >
          {{ item.price }}
        </del><strong class="search-result-price__discounted">
          {{ item.discounted_price }}
        </strong>
      </p>
    </div>
  </a>
</template>

<script>

export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped>
</style>

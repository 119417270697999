import Vue from 'vue'
import Promotions from '../components/promotions/promotions.vue'

export default class Product {
  constructor() {
    this.apps = {}
    this.initializeAll()
  }

  clearApps() {
    for (let key of Object.keys(this.apps)) {
      this.apps[key].$destroy()
    }

    this.apps = {}
  }

  initializeAll() {
    let $this = this

    const products = document.getElementsByClassName("gy-product")
    let idx = 1

    for (let product of products) {
      let id = "gy-product-" + Date.now() + "-" + idx
      product.setAttribute('id', id)

      if ($this.apps[id] == undefined) {
        setTimeout(() => {
          $this.apps[id] = $this.initialize(product)
        })
      }

      idx++
    }
  }

  /*
   * Initialize Vue app
   */
  initialize(product) {
    return new Vue({
      el: product,
      components: {
        Promotions
      },
      data() {
        return {
          versions: {},
          productId: null,
          load: false
        }
      },
      computed: {
        alreadyAddedToCart() {
          if (this.$children[0]) {
            return this.$children[0].alreadyAddedToCart()
          }
          else {
            return false
          }
        }
      },
      beforeMount() {
        if (this.$el.attributes.productId) {
          this.productId = this.$el.attributes.productId.value
        }

        if (this.$el.attributes.versions) {
          this.versions = this.$el.attributes.versions.value
        }

        if (this.$el.attributes.load) {
          this.load = true
        }
      }
    })
  }
}
